import React, { useEffect } from "react";
import { APP_CONST } from "../../../../config/const";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import MaintenanceImg from "../../../../assets/images/banner/maintain.svg";

const Maintenance = ({
  organisationStatus = APP_CONST.ORGANISATION_STATUS.Live,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    // check organisation status
    if (
      organisationStatus === null ||
      organisationStatus === APP_CONST.ORGANISATION_STATUS.Live
    ) {
      navigate("/");
    }
  }, [organisationStatus]);

  return (
    <div>
      <div className="MaintainSection">
        <div className="MaintainSectionContent">
          <div className="MaintainSection__title">Website is in </div>
          <p className="maintainword">Maintenance</p>
          <p className="backsoon">We'll be Back Soon!!</p>
          <p>
            It is a long established fact that a reader will be distracted by
            the readable content of a page when looking at its layout. The point
            of using Lorem Ipsum is that it has a more-or-less normal
            distribution of letters.
          </p>
        </div>
        <div className="MaintainImg">
          <img src={MaintenanceImg} alt="MaintenanceImg" />
        </div>
      </div>
    </div>
  );
};
export default Maintenance;
